import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { Row, Col, Gap, Text, Section, Title, Button, VH, HpTop, Fill, SEO } from '../components'
import { up, addResponsivity } from '../lib/styles'

const loremipsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
gravida. Risus commodo viverra Lorem ipsum dolor sit amet, consectetur adipiscing
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
suspendisse ultrices gravida. Risus commodo viverra Lorem ipsum dolor sit amet,
consectetur`

const storyBp = 1506

const TextAlignGap = styled.div`
    height: 0;

    ${up('tablet')} {
        /* height: 180px; */
    }

    ${up(1250)} {
        height: 180px;
    }

    ${up(storyBp)} {
        height: 60px;
    }
    /* ${up(120)} {} */
`

class Story extends Component {
  render() {
    const {
      location: { pathname },
      data: {
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
        image13,
        image14,
        image15,
        image16,
        image17,
        image18,
      },
    } = this.props

    const content = [
      {
        image: image1,
        time: '09 / 2017',
        title: 'FIRST SKETCH',
        text:
          'Zuri started as a visual sketch. First drawn by the company founder, Michal Illich, with some crayons borrowed from his kids. Later redrawn by a professional aerospace designer to the form you see here.',
      },
      {
        image: image2,
        time: '05 / 2018',
        title: 'FIRST MODEL',
        text:
          'To prove the basic idea, we built a small model with a two-meter wingspan. This was used to test the flight characteristics. Building a model using common hobby components allowed for cheap experimenting and rapid progress.',
      },
      {
        image: image3,
        time: '08 / 2018',
        title: 'PROOF OF CONCEPT',
        text:
          'Next, we wanted to test the scalability of our concept. We hired an airplane designer to create a five-meter prototype. We tested it first in horizontal mode, as an airplane. Then we added the vertical takeoff and landing system and tested the aircraft in hover mode. This model is our first unmanned aircraft registered with the local CAA authorities.',
      },
      {
        image: image4,
        time: '10 / 2018',
        title: 'PALE FIRE CAPITAL FUNDING',
        text:
          'After proving that the idea has wings, we approached Jan Barta of the PFC investment fund to consult about a fundraising strategy. He liked Zuri so much that he decided to invest €600k of the Pale Fire Capital money in the company as a convertible note.',
      },
      {
        image: image5,
        time: '01 / 2019',
        title: 'GROWING OUR TEAM',
        text:
          'With the basic funding secured, we started to grow our team. From 6 in the autumn of 2018 we grew to 15 by the summer of 2019. Professional aerospace engineers, aerodynamics experts, software developers and other specialists joined Zuri on its mission.',
      },
      {
        image: image6,
        time: '03 / 2019',
        title: 'KIWI AS A STRATEGIC PARTNER',
        text:
          'We joined forces with Kiwi.com, a very successful Czech startup, which sells airline tickets. Kiwi.com invested €200k and is helping us with their thorough knowledge of the air travel industry.',
      },
      {
        image: image7,
        time: '05 / 2019',
        title: 'TESTING',
        text:
          'Things are getting serious. We are already testing components to be used in our one-seater prototype. Choosing the right electromotors, controllers and batteries is crucial for the success of our demonstrator.',
      },
      {
        image: image8,
        time: '09 / 2019',
        title: 'AERODYNAMIC TESTING ',
        text:
          'The first year we used computational fluid dynamics (CFD), a computer simulation of aerodynamics. Then, in the summer of 2019, we 3D-printed a model and verified the aerodynamics in a wind tunnel at the Czech Aerospace Research Centre.',
      },
      {
        image: image9,
        time: '2020',
        title: 'BUILDING DEMONSTRATOR ',
        text:
          'Currently, we are building our first experimental aircraft. It will be the first to be used as a “flying wing,” an unmanned aerial vehicle for testing all the components in hover mode. In the second phase, it will be upgraded to a full single-seater airplane.',
      },
      {
        image: image10,
        time: '12 / 2020 +',
        title: 'Demonstrator ground testing',
        text:
          'Following subscale test models, ZURI had built a large-scale technology demonstrator with an 11m wingspan. Even in the age of high-end CAD software, the team at ZURI has to verify each detail in reality. Ground tests of the technology will be finalized in June 2021, followed by hover tests in July 2021.',
      },
      {
        image: image11,
        time: '05 / 2021',
        title: 'New angel investors onboard',
        text:
          'Finding new partners and investors is a crucial task. So with pleasure, we welcomed on board Marek Rosa from GoodAI and Zdeněk Polách from Kaora, who recently invested a total of € 300,000 in the ZURI project and thus joined our previous investors Pale Fire Capital and Kiwi.com.',
      },
      {
        image: image12,
        time: '09 / 2021',
        title: 'The first hover test',
        text: 'We have achieved a true milestone in developing our hybrid VTOL aircraft. On 15th September 2021, ZURI team carried out the very first hover test. Our large-scale technological demonstrator with an 11m wingspan took off the ground and performed its first hover flight.',
      },
      {
        image: image13,
        time: '10 / 2021',
        title: '€1.3M angel investment',
        text: 'ZURI welcomes a round of angel investors on board! With a lead investment of €750k, Mr. Jaroslav Beck becomes the largest single investor in ZURI’s history. Five other angels contributed additional €550k in this round, so ZURI had raised over €2.4 million in total (including conversion of previous notes) and secured necessary financing for the current development phase.',
      },
      {
        image: image14,
        time: '01 / 2022',
        title: 'Zuri 2.0',
        text: 'ZURI officially introduces the next generation of its hybrid VTOL aircraft, designated ZURI 2.0. After hundreds of hours of development and testing of large-scale flight demonstrator and sub-scale models, the ZURI team prepared the current version of the aircraft. This version builds on the knowledge the team has gained during the previous period and takes the ZURI project to the next stage of development.',
      },
      {
        image: image15,
        time: '10 / 2022',
        title: 'Updated design',
        text: 'We unveiled updates of the Zuri 2.0 concept. The seating configuration changed to 1+2+2 - one pilot and two rows of two passengers each. The aircraft now also has a V-tail instead of the traditional tail.',
      },
      {
        image: image16,
        time: '04/2023 ',
        title: 'VR simulator for 5 people',
        text: 'We attended the Aero Friedrichshafen, largest general aviation event in Europe. The main attraction at our stand was a unique VR simulator for the pilot and four passengers. This device, developed by our team, provided visitors with a futuristic experience — flying in the ZURI 2.0.',
      },
      {
        image: image17,
        time: '09/2023',
        title: 'Zuri demonstrator - longest flight',
        text: 'Thanks to improvements in the control system and weight reduction of the ZURI TD (technological demonstrator) with its 11-meter wingspan, we achieved a record-breaking flight. This accomplishment included not only unprecedented height and flight duration but also quick responsiveness to the pilot\'s commands.',
      },
      {
        image: image18,
        time: '04/2024',
        title: '“Skeleton” at Aero',
        text: 'For this year’s Aero Friedrichshafen event, we prepared a special treat with a new ground testbed showcasing our latest stage of development. In addition to attracting thousands of visitors to our stand, the Skeleton also caught the attention of journalists from around the world.',
      },
    ]

    return (
      <>
        <SEO
          title="Story | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Gap.Top />
        <Fill.Top />
        <Section.Simple>
          {content.map(({ image, time, title, text }, key) => (
            <Row
              key={time}
              alignItems="stretch"
              responsive
              bp="desktop"
              maxWidth="1690px"
              justifyContent="space-between"
            >
              <Col maxWidth="997px" width="100%" height="auto" critical={key === 0} background="black">
                <Img fluid={image.image.fluid} />
                {key !== content.length - 1 && <Fill height="38px" showAfter="desktop" />}
              </Col>
              <Gap gap="40px" mobileGap="40px" bp="desktop" />
              <Col withPagePadding maxWidth="510px" style={{ paddingBottom: '16px', paddingTop: '16px' }}>
                {/* <Gap gap="120px" mobileGap="0" bp="desktop" /> */}
                <Col grow="1" />
                <div>
                  <Title.Story bp={storyBp} fontSize="106px">{time}</Title.Story>
                  <Gap gap="30px" mobileGap="10px" bp={storyBp} />
                  <Title.StorySub>{title}</Title.StorySub>
                  <Gap gap="80px" mobileGap="40px" bp={storyBp} />
                  <Text.Small style={{ lineHeight: '30px' }}>{text}</Text.Small>
                  <TextAlignGap />
                  <Gap gap="0" mobileGap="80px" bp="desktop" />
                </div>
                <Col grow="2" />
              </Col>
            </Row>
          ))}
        </Section.Simple>
      </>
    )
  }
}

export default Story

export const pageQuery = graphql`
  fragment StoryImage on File {
    image: childImageSharp {
      fluid(maxWidth: 977, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "images/story/1.jpg" }) {
      ...StoryImage
    }
    image2: file(relativePath: { eq: "images/story/2.jpg" }) {
      ...StoryImage
    }
    image3: file(relativePath: { eq: "images/story/3.jpg" }) {
      ...StoryImage
    }
    image4: file(relativePath: { eq: "images/story/4.jpg" }) {
      ...StoryImage
    }
    image5: file(relativePath: { eq: "images/story/5.jpg" }) {
      ...StoryImage
    }
    image6: file(relativePath: { eq: "images/story/6.jpg" }) {
      ...StoryImage
    }
    image7: file(relativePath: { eq: "images/story/7.jpg" }) {
      ...StoryImage
    }
    image8: file(relativePath: { eq: "images/story/8.jpg" }) {
      ...StoryImage
    }
    image9: file(relativePath: { eq: "images/story/9.jpg" }) {
      ...StoryImage
    }
    image10: file(relativePath: { eq: "images/story/10.jpg" }) {
      ...StoryImage
    }
    image11: file(relativePath: { eq: "images/story/11.jpg" }) {
      ...StoryImage
    }
    image12: file(relativePath: { eq: "images/story/12.jpg" }) {
      ...StoryImage
    }
    image13: file(relativePath: { eq: "images/story/13.jpeg" }) {
      ...StoryImage
    }
    image14: file(relativePath: { eq: "images/story/14.jpg" }) {
      ...StoryImage
    }
    image15: file(relativePath: { eq: "images/story/15.jpg" }) {
      ...StoryImage
    }
    image16: file(relativePath: { eq: "images/story/16.jpg" }) {
      ...StoryImage
    }
    image17: file(relativePath: { eq: "images/story/17.jpg" }) {
      ...StoryImage
    }
    image18: file(relativePath: { eq: "images/story/18.jpg" }) {
      ...StoryImage
    }
  }
`
